import { Classes } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import { HexButton } from "../../../../hex-components/HexButton.js";
import { Txt } from "../../../../hex-components/Txt.js";
import { maybePluralize } from "../../../../util/string.js";
import {
  ColumnRowTop,
  ColumnRowWrapper,
  ColumnTypeBadge,
  ColumnTypeBadgeWrapper,
  TextContainer,
} from "../../../data/schema-browser-v2/SharedStyles.js";
import { Heading } from "../../../Heading.js";
import { TableCellIcon } from "../../../icons/CustomIcons.js";

export const DataPillTooltipContentWrapper = styled.div`
  padding: 7px 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 200px;

  color: ${({ theme }) => theme.fontColor.MUTED};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ColumnItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  font-family: ${({ theme }) => theme.fontFamily.MONO};
`;

const ParamName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.MONO};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  gap: 4px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

const DataSourceTableIcon = styled(TableCellIcon)`
  &&&& {
    margin: 0;
  }
`;

const NUM_COLUMNS_TO_SHOW = 5;

interface DataPillTooltipContentProps {
  icon?: "data-source-table";
  tableName: string;
  description: string | null;
  columns: ReadonlyArray<{ name: string; type: string }>;

  // optional action at the bottom of the tooltip content
  actionName?: string;
  onAction?: (evt: React.MouseEvent) => void;
}

export const DataPillTooltipContent: React.ComponentType<DataPillTooltipContentProps> =
  React.memo(function DataPillTooltipContent({
    actionName,
    columns,
    description,
    icon,
    onAction,
    tableName,
  }) {
    const numColumns = columns.length;
    const numAdditionalColumns = numColumns - NUM_COLUMNS_TO_SHOW;
    return (
      <>
        <ParamName>
          {icon === "data-source-table" ? <DataSourceTableIcon /> : undefined}
          <Txt ellipsize={true} title={tableName}>
            {tableName}
          </Txt>
        </ParamName>
        {description && (
          <Txt ellipsize={true} title={description}>
            {description}
          </Txt>
        )}
        {numColumns > 0 && (
          <ColumnWrapper>
            <Heading renderAs="h5">Columns</Heading>
            <ColumnItems>
              {columns.slice(0, NUM_COLUMNS_TO_SHOW).map((column) => {
                return (
                  <div key={column.name}>
                    <ColumnRowWrapper>
                      <ColumnRowTop>
                        <TextContainer>
                          <Txt ellipsize={true} title={column.name}>
                            {column.name}
                          </Txt>
                        </TextContainer>
                        <ColumnTypeBadgeWrapper>
                          <ColumnTypeBadge
                            className={Classes.TEXT_OVERFLOW_ELLIPSIS}
                            title={column.type}
                          >
                            {column.type}
                          </ColumnTypeBadge>
                        </ColumnTypeBadgeWrapper>
                      </ColumnRowTop>
                    </ColumnRowWrapper>
                  </div>
                );
              })}
            </ColumnItems>
            {numColumns > NUM_COLUMNS_TO_SHOW && (
              <Txt fontSize="extra_small">
                {numAdditionalColumns} more{" "}
                {maybePluralize(numAdditionalColumns, "column", "columns")}...
              </Txt>
            )}
          </ColumnWrapper>
        )}
        {actionName && onAction && (
          <HexButton
            className={Classes.POPOVER_DISMISS}
            intent="primary"
            onClick={onAction}
          >
            {actionName}
          </HexButton>
        )}
      </>
    );
  });
